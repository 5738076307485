// All static Text shouwn on website
import imgslider1 from '../assets/imgslider1.png';
import imgslider2 from '../assets/imgslider2.png';
import imgslider3 from '../assets/imgslider3.png';
import jacobimg from '../assets/jacobimg.webp';
import noahimg from '../assets/noahimg.png';
import aigeneratedaudio from '../assets/aigeneratedautio.jpeg';
import aigenerateddeepfake from '../assets/aigenerateddeepfake.jpeg';
import aigeneratedface from '../assets/aigeneratedface.jpeg';
import aigeneratedimage from '../assets/aigeneratedimage.jpeg';
import aigeneratedtext from '../assets/aigeneratedtext.jpeg';


// Navbar items
// export const navLinks = [
//     {
//         id: "home",
//         title: "Home"
//     },
//     {
//         id: "project",
//         title: "Project"
//     },
//     {
//         id: "about_us",
//         title: "About us"
//     },
//     {
//         id: "models",
//         title: "Models"
//     }
// ]

export const navLinks = [
    {
        id: "aletheia",
        title: "Face Detection"
    },
    {
        id: "argus",
        title: "Image Detection"
    },
    {
        id: "content-library-general",
        title: "Content Library"
    },
    {
        id: "privacy-policy",
        title: "Privacy Policy"
    }
]




export const general_featured = [
    {
        title: "From Creation to Detection: AI's Central Role in Misinformation",
        link: "https://www.linkedin.com/pulse/from-creation-detection-ais-central-role-jacob-heldt%3FtrackingId=sXEbSOSOS8a5gPChd2mrow%253D%253D/?trackingId=sXEbSOSOS8a5gPChd2mrow%3D%3D",
        description: "AI can create and detect deepfakes, a threat to truth. SyntheticEye is an app to verify content authenticity.",
        date: "26 Aug 2023",
        time_to_read: "2min",
        author: "Jacob Heldt, Noah Brömme",
        icon: "FaEye",
        form: "Article"
    },
    {
        title: "The Dangers of AI generated Content",
        link: "https://www.linkedin.com/pulse/dangers-ai-generated-content-jacob-heldt/?trackingId=VUvznYHnQM6AHB1zg97uwA%3D%3D",
        description: "AI-generated content can spread misinformation, bias, plagiarism, and reduce human creativity. We need ethical guidelines and AI detectors.",
        date: "23 Jun 2023",
        time_to_read: "3min",
        author: "Jacob Heldt, Noah Brömme",
        icon: "FaNewspaper",
        form: "Article"
    },
    {
        title: "What are deepfakes – and how can you spot them?",
        link: "https://www.theguardian.com/technology/2020/jan/13/what-are-deepfakes-and-how-can-you-spot-them",
        description: "AI-generated fake videos are becoming more common and dangerous. They can deceive, harass and erode trust. How to detect and regulate them?",
        date: "13 Jan 2020",
        time_to_read: "10min",
        author: "The Guardian, Ian Sample",
        icon: "FaBalanceScaleLeft",
        form: "Article"
    },
    {
        title: "What Is AI-Generated Content?",
        link: "https://proofed.com/knowledge-hub/what-is-ai-generated-content/",
        description: "AI can generate or assist content creation, but has risks and limitations. Use with caution and proofread before publishing.",
        date: "21 Mr 2023",
        time_to_read: "7min",
        author: "Proofed",
        icon: "FaGlasses",
        form: "Article"
    },
]
export const general_standard = [
    {
        title: "How to spot AI-generated text",
        link: "https://www.technologyreview.com/2022/12/19/1065596/how-to-spot-ai-generated-text/",
        description: "AI-generated text is hard to detect and can mislead online. We need better tools, transparency and education to counter it.",
        date: "19 Dec 2022",
        time_to_read: "10min",
        author: "MIT Technology Review, Melissa Heikkilä",
        icon: "FaGlasses",
        form: "Article"
    },
    {
        title: "How Do AI Detectors Work? | Methods & Reliability",
        link: "https://www.scribbr.com/ai-tools/how-do-ai-detectors-work/",
        description: "AI detectors exist that help at identifying AI-generated text. They are useful but not completely reliable. Learn how to use and spot AI-generated content.",
        date: "1 May 2023",
        time_to_read: "8min",
        author: "Scribbr, Jack Caulfield",
        icon: "FaTools",
        form: "Article"
    },
    {
        title: "The carbon impact of artificial intelligence",
        link: "https://www.nature.com/articles/s42256-020-0219-9",
        description: "AI has a significant carbon footprint due to its energy-intensive nature, raising concerns and prompting shifts towards more sustainable practices.", 
        icon: "FaLeaf",
        form: "Article"
    },
    {
        title: "Ethical concerns mount as AI takes bigger decision-making role",
        link: "https://news.harvard.edu/gazette/story/2020/10/ethical-concerns-mount-as-ai-takes-bigger-decision-making-role/",
        description: "AI has many benefits and risks for society. Experts debate how to regulate and use it ethically.",
        date: "26 Oct 2020",
        time_to_read: "10min",
        author: "The Harvard Gazette, Christina Pazzanese",
        icon: "FaBalanceScaleLeft",
        form: "Article"
    },
    {
        title: "Advances in artificial intelligence raise new ethics concerns",
        link: "https://www.youtube.com/watch?v=l5nTlHeqYOQ",
        description: "The video discusses how AI's negative effects can be curbed, by pursuing inclusive and public-interest-oriented development alongside technical progress.",
        date: "10 Jan 2023",
        time_to_read: "8min",
        author: "PBS NewsHour",
        icon: "FaBalanceScaleLeft",
        form: "Video"
    },
    {
        title: "Artificial Intelligence’s Environmental Costs and Promise",
        link: "https://www.cfr.org/blog/artificial-intelligences-environmental-costs-and-promise",
        description: "AI is viewed as a means to combat climate change, but tech companies must act to ensure it doesn’t contribute to environmental degradation.",
        icon: "FaLeaf",
        form: "Article"
    },
    {
        title: "If it wasn’t created by a human artist, is it still art?",
        link: "https://news.harvard.edu/gazette/story/2023/08/is-art-generated-by-artificial-intelligence-real-art/",
        description: "Harvard faculty from different arts fields share their views on AI as a threat, collaborator, or tool.",
        date: "15 Aug 2023",
        time_to_read: "7min",
        author: "The Harvard Gazette, Liz Mineo",
        icon: "FaPaintBrush",
        form: "Article"
    },
    {
        title: "How artificial intelligence is helping tackle environmental challenges",
        link: "https://www.unep.org/news-and-stories/story/how-artificial-intelligence-helping-tackle-environmental-challenges",
        description: "Accessing, interpreting, and acting on the abundant climate data is crucial for managing environmental crises, and AI is central to this process.",
        icon: "FaLeaf",
        form: "Article"
    },
    {
        title: "What is AI ethics?",
        link: "https://www.ibm.com/topics/ai-ethics",
        description: "AI ethics focuses on ensuring fairness, transparency, and responsible use of AI technologies, with companies like IBM adopting guidelines.",
        icon: "FaBalanceScaleLeft",
        form: "Article"
    },
    {
        title: "New York Times Artificial Intelligence Library",
        link: "https://www.unesco.org/en/artificial-intelligence/recommendation-ethics",
        description: "Library of multiple New York Times articles about Artificial Intelligence.",
        icon: "FaRobot",
        form: "Collection"
    },
    {
        title: "Ethics of Artificial Intelligence",
        link: "https://www.unesco.org/en/artificial-intelligence/recommendation-ethics",
        description: "With its unique mandate, UNESCO has led the international effort to ensure that science and technology develop with strong ethical guardrails for decades.",
        icon: "FaBalanceScaleLeft",
        form: "Article"
    },
    {
        title: "We Need to Talk About How Good A.I. Is Getting",
        link: "https://www.nytimes.com/2022/08/24/technology/ai-technology-progress.html",
        description: "We’re in a golden age of progress in artificial intelligence. It’s time to start taking its potential and risks seriously.",
        icon: "FaRobot",
        form: "Article"
    },
    {
        title: "Who will benefit from AI? ",
        link: "https://news.mit.edu/2023/who-will-benefit-ai-machine-usefulness-0929",
        description: "In a talk, Daron Acemoglu offers vision of \“machine usefulness,\” rather than autonomous “intelligence,” to help workers and spread prosperity.",
        icon: "FaUserAlt",
        form: "Article"
    },
]


export const technical_featured = [
    {
        title: "PyTorch for Deep Learning & Machine Learning",
        link: "https://youtu.be/V_xro1bcAuA?si=7WUPbfnTcw8a_-lI",
        description: "Learn PyTorch for deep learning in this comprehensive course for beginners. PyTorch is a popular machine learning framework we used for our model.",
        date: "6 Oct 2022",
        time_to_read: "24h",
        author: "freeCodeCamp, mrdbourke",
        icon: "FaFire",
        form: "Video"
    },
    {
        title: "Learn the Basics",
        link: "https://pytorch.org/tutorials/beginner/basics/intro.html",
        description: "Familiarize yourself with basic PyTorch concepts. Topcis include Datasets, Tensors, and Transformers.",
        date: "",
        time_to_read: "3min",
        author: "Pytorch",
        icon: "FaFireAlt",
        form: "Text"
    },
    {
        title: "Neural Networks by 3Blue1Brown",
        link: "https://www.youtube.com/playlist?list=PLZHQObOWTQDNU6R1_67000Dx_ZCJB-3pi",
        description: "Learn the fundamentals of backpropagation, a crucial algorithm in machine learning, as well as neural networks.",
        date: "",
        time_to_read: "",
        author: "3Blue1Brown",
        icon: "FaEye",
        form: "Video"
    },
]
export const technical_standard = [
    {
        title: "Deep Learning With PyTorch - Full Course",
        link: "https://www.youtube.com/watch?v=c36lUUr864M&t=6857s",
        description: "In this course, the most important concepts for machine learning are covered",
        date: "",
        time_to_read: "4:30h",
        author: "Patrik Loeber",
        icon: "FaFire",
        form: "Video"
    },
    {
        title: "Pytorch: Zero to GANs",
        link: "https://www.youtube.com/watch?v=GIsg-ZUy0MY&t=16946s",
        description: "This relatively beginner-friendly course teaches you how to build deep learning models with PyTorch and Python.",
        date: "1 May 2023",
        time_to_read: "9:30h",
        author: "freeCodeCamp, Jovian",
        icon: "FaArrowUp",
        form: "Video"
    },
    {
        title: "Deep Learning Basics: Introduction and Overview",
        link: "https://www.youtube.com/watch?v=O5xeyoRL95U&t=652s",
        description: "This is an introductory lecture for a MIT course by Lex Fridman. The basics of deep learning are explained here",
        date: "Jan 11, 2019",
        time_to_read: "1h",
        author: "Lex Fridman",
        icon: "FaCode",
        form: "Video"
    },
    {
        title: "Welcome to Pytorch Tutorials",
        link: "https://pytorch.org/tutorials/",
        description: "Familiarize yourself with PyTorch concepts and modules. Learn how to load data, build deep neural networks, train and save your models in this quickstart guide.",
        date: "",
        time_to_read: "",
        author: "PyTorch",
        icon: "FaBook",
        form: "Text"
    },
    {
        title: "Machine Learning Crash Coursewith TensorFlow APIs",
        link: "https://developers.google.com/machine-learning/crash-course/",
        description: "Google's fast-paced, practical introduction to machine learning, featuring a series of lessons with video lectures, real-world case studies, and hands-on practice exercises.",
        date: "",
        time_to_read: "",
        author: "Google",
        icon: "FaGoogle",
        form: "Video"
    },
    {
        title: "Machine Learning Tutorial",
        link: "https://www.geeksforgeeks.org/machine-learning/",
        description: "Machine Learning tutorial covers basic and advanced concepts, specially designed to cater to both students and experienced working professionals.",
        date: "",
        time_to_read: "",
        author: "geeksforgeeks",
        icon: "FaBookOpen",
        form: "Text"
    },
    {
        title: "Stanford CS229: Machine Learning Full Course taught by Andrew Ng",
        link: "https://www.youtube.com/playlist?list=PLoROMvodv4rMiGQp3WXShtMGgzqpfVfbU",
        description: "This course, taught by Andrew Ng, offers a thorough introduction to statistical pattern recognition and machine learning. A wide range of topics is covered.",
        date: "",
        time_to_read: "",
        author: "Stanford Online",
        icon: "FaVideo",
        form: "Video"
    },
    {
        title: "Machine Learning for Everybody - Full Course",
        link: "https://www.youtube.com/watch?v=i_LwzRVP7bg&t=3162s",
        description: "This machine learning course covers the basics of ML in a very intuitive and beginner-friendly manner",
        date: "Sep 26, 2022",
        time_to_read: "4h",
        author: "freeCodeCamp, Kylie Ying",
        icon: "FaRegSmile",
        form: "Video"
    },
    {
        title: "Crash Course Artificial Intelligence",
        link: "https://www.youtube.com/playlist?list=PL8dPuuaLjXtO65LeD2p4_Sb5XQ51par_b",
        description: "This university-level course delves deep into the world of AI and its three primary Machine Learning facets: supervised, unsupervised, and reinforcement.",
        date: "",
        time_to_read: "",
        author: "CrashCourse",
        icon: "FaRobot",
        form: "Video"
    },
    {
        title: "Pytorch - Deep learning w/ Python",
        link: "https://www.youtube.com/playlist?list=PLQVvvaa0QuDdeMyHEYc0gxFpYwHY2Qfdh",
        description: "This comprehensive youtube course consisting of multiple videos covers many topics, essential for machine learning.",
        date: "",
        time_to_read: "",
        author: "sentdex",
        icon: "FaCode",
        form: "Video"
    },
    {
        title: "MIT Introduction to Machine Learning",
        link: "https://www.youtube.com/playlist?list=PLtBw6njQRU-rwp5__7C0oIVt26ZgjG9NI",
        description: "This comprehensive course is available on YouTube and covers a wide range of topics.",
        date: "",
        time_to_read: "",
        author: "MIT, Alexander Amini",
        icon: "FaCamera",
        form: "Video"
    },
    {
        title: "A Course in Machine Learning",
        link: "http://ciml.info/",
        description: "Dive into the dynamic realm of machine learning with Hal Daumé III's comprehensive guide. This course offers a detailed overview of key concepts.",
        date: "",
        time_to_read: "",
        author: "Hal Daumé III",
        icon: "FaBook",
        form: "Text"
    }
]




// Our project
export const project = [
    {
        id: "goal",
        bg: "",
        title: "Our Goal",
        text: "Our goal is to develop a comprehensive application that detects and distinguishes AI-generated content from human-generated content across various media formats, starting with images and expanding to video, text, and audio. Thus enabling anyone to recognize AI-generated content and combat the spread of misinformation in the digital world."
    },
    {
        id: "finalProduct",
        bg: "",
        text: "We want to develop an advanced and user-friendly application, that has the ability to easily detect AI-generated content. It will incorporate cutting-edge detection models and provide a seamless experience for users, enabling them to make informed decisions."
    },
    {
        id: "stepsInvolved",
        bg: "",
        text: "Specialized models are built for images, videos, text, and audio using machine learning and deep learning methods. Diverse datasets are acquired and utilized to train the detection models, enhancing their accuracy and reliability."
    }
]


// About Us
export const imageSlider = [
    {
        id: "img-1",
        source: imgslider1
    },
    {
        id: "img-2",
        source: imgslider2
    },
    {
        id: "img-3",
        source: imgslider3
    }
]

export const about = [
    {
        id: "noah",
        title: "Noah Brömme",
        content: "Hello! I have lived in NY for 9 years. I have found interest in technology and AI. I also play the piano and the guitar and I am amazed by the art of photography. With the rise of deepfakes and AI-generated content in our everyday lives, I found myself interested in these topics.",
        personimg: jacobimg
    },
    {
        id: "jacob",
        title: "Jacob Heldt",
        content: "Hello! After living in the Netherlands for 6 years, I now live in New York since 2 years and have developed passion for technology, creativity, and innovation. Machine Learning is a significant area of interest for me, and I am constantly striving to improve my understanding and expertise in this field. ",
        personimg: noahimg
    }
]


// Our Models
export const theModels = [
    {
        id: "imgDetection",
        title: "Image Detector",
        modelimg: aigeneratedimage,
        imgsubtitle: '"A renaissance painting of an elephant in a tuxedo" by bing image creator',
        content: 'A machine learning model that can detect AI generated images and can distinguish them from real images. Ranging from cars, houses, apples and all kinds of everyday objects'
    },
    {
        id: "faceDetection",
        title: 'AI Generated Face Detection',
        modelimg: aigeneratedface,
        imgsubtitle: '"portrait of a young man" by bing image creator',
        content: 'A machine learning model that can detect faces generated by AI and can distinguish them from real faces. This is useful for example when detecting fake profiles on social media platforms'
    },
    {
        id: "textDetection",
        title: 'Text Detector',
        modelimg: aigeneratedtext,
        imgsubtitle: '"Text" by bing image creator',
        content: 'A machine learning model that detects AI generated text. These texts can for example be based on ChatGPT generations.'
    },
    {
        id: "audioDetection",
        title: 'Audio Detector',
        modelimg: aigeneratedaudio,
        imgsubtitle: '"Audio" by bing image creator',
        content: 'A machine learning model that can detect AI generated audio and music. This can range from voices to generated music.'
    },
    {
        id: "deepfakeDetection",
        title: 'Deepfake Detector',
        modelimg: aigenerateddeepfake,
        imgsubtitle: '"Video" by bing image creator',
        content: 'A machine learning model that can detect advanced deepfakes. This is the highest form of AI generated content detection.'
    }
]


