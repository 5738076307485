import React, { useState } from 'react';
import styles from '../style'
import logo from '../assets/Logo.png'
import Button from './Button'
import herobg from '../assets/herobg.png'
import Popup from './Popup'
import { useNavigate } from 'react-router-dom';

const Hero = ({ handleClick }) => {

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const handleOpenPopup = () => {
    navigate('/aletheia');
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    window.open("https://chrome.google.com/webstore/detail/syntheticeye-ai-detector/jhnehjooeiopekmlgcpmdmliifccjhlj?hl=en", "_blank");
  };  

  return (
    <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY} font-poppins`}>
      <div className='absolute right-1/3 z-[0]'>
        <div className='blue__gradient h-64 w-80'></div>
      </div>
      <div className='absolute right-2/3 z-[0]'>
        <div className='darkblue__gradient h-64 w-40'></div>
      </div>
      <div className='absolute right-1/2 z-[0]'>
          <div className='darkblue__gradient h-64 w-64'></div>
    </div>

      <div className={`flex-1 ${styles.flexStart} flex-col md:ml-36 md:mr-36 ml-8 mr-8 mt-10`}>

      <div className='relative w-full mb-12'>

      <div>
        
      </div>
      <h2 className='font-secondary md:text-3xl font-poppins md:mb-4 z-20 relative text-lg mb-8'>Advanced AI Detectors</h2>      
      <h1 className='z-20 relative text-white md:text-9xl font-righteous md:mb-4 text-5xl mb-8'>SyntheticEye</h1>
      <p className='text-white font-light relative z-20 text-xs md:text-base'>Created by Jacob Heldt and Noah Brömme</p>
    </div>

    <img 
      src={`${herobg}`}
      alt="SyntheticEye Logo" 
      className="absolute z-10 md:right-36 md:-top-0 md:mt-4 md:opacity-60 mt-[-180px] opacity-40 md:h-[350px] md:w-[470px] w-[350px] h-[175px] md:visible invisible" 
  />

      <Button handleClick={handleClick} text="Try it" className="mr-4"/>
      <Button handleClick={handleButtonClick} text="Get Extension" variant="outline" className="hidden md:inline-block"/>
      <Popup isOpen={isOpen} handleClose={handleClosePopup} />

        {/* Quote */}
        <div className="flex flex-row items-center py-6 px-9 bg-quote-gradient rounded-md mt-16">
          <p>
            <span className='text-white font-light tracking-wide font-poppins md:text-base text-sm'>"Artificial intelligence has the power to create astonishingly realistic and compelling content, but with that power comes the inherent danger of manipulation, misinformation, and the erosion of trust in what is real and genuine."<span className='text-gray-300 not-italic'>  - ChatGPT</span></span>
          </p>
          
        </div>
        
        <div className='flex flex-row flex-col items-center justify-center w-full rounded-md mt-12'>
          <span className='mb-0'><span className='secondary-gradient md:text-4xl text-2xl font-bold'>90% </span><span className='text-white text-lg font-light md:text-lg text-xs'>of online content may be AI generated by 2026</span></span>
          <span className='text-gray-300 italic md:text-sm font-light text-xs'>Deep Fakes and the Infocalypse, Nina Schick</span>
        </div>

      </div>
    </section>
  )
}

export default Hero 