import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { DotLoader, RingLoader } from "react-spinners";
import { AiFillCheckCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import FaceDetectors from './FaceDetectors';
import samplegradient from '../assets/samplegradient.jpeg'

const Aletheia = () => {
    const [result, setResult] = useState({ probabilities: null, gradCamImage: null });
    const [error, setError] = useState(null);
    const fileInput = useRef(null);
    const [fileSelected, setFileSelected] = useState(false);
    const [isConsentGiven, setIsConsentGiven] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);

    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        setIsLoaded(true);
    }, []);


    /* const handleSubmit = async () => {
        const selectedFile = fileInput.current?.files[0];
    
        setError(null);
    
        if (!isConsentGiven) {
            setError("You must agree to the processing of the image as outlined in the Privacy Policy.");
            return;
        }
    
        if (!selectedFile) {
            setError("Please select a file first.");
            setFileSelected(false);  // No file selected
            return;
        }
    
        setFileSelected(true);
        setLoading(true);
    
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        try {
            let data;

                const response = await fetch('https://syntheticeye-dev.onrender.com/predict_aletheia4_endpoint', {
                    method: 'POST',
                    mode: 'cors',
                    body: formData,
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                setIsSubmitted(true);
    
                if (!response.ok) throw new Error("Error in initial response");
    
                data = await response.json(); // Convert the response to JSON
    
                // Check if the required fields are present in the data
                if (data.CG !== undefined && data.GAN !== undefined && data.Real !== undefined) {
                    // Fetch Grad-CAM image
                    try {
                        const gradCamResponse = await fetch('https://syntheticeye-dev.onrender.com/predict-gradcam-aletheia4', {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'Accept': 'image/jpeg'
                            }
                        });
    
                        if (!gradCamResponse.ok) throw new Error('Failed to load Grad-CAM image');
    
                        const gradCamImageBlob = await gradCamResponse.blob();
                        const gradCamImageUrl = URL.createObjectURL(gradCamImageBlob);
                        setResult({ probabilities: data, gradCamImage: gradCamImageUrl });
                    } catch (gradCamError) {
                        console.error('Grad-CAM Error:', gradCamError);
                        // Handle Grad-CAM error accordingly
                    }
                } else {
                    throw new Error("Invalid response structure");
                }
        } catch (err) {
            console.error('Error:', err);
            setError("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    }; */

    const handleSubmit = async () => {
        const selectedFile = fileInput.current?.files[0];
    
        setError(null);
    
        if (!isConsentGiven) {
            setError("You must agree to the processing of the image as outlined in the Privacy Policy.");
            return;
        }
    
        if (!selectedFile) {
            setError("Please select a file first.");
            setFileSelected(false);  // No file selected
            return;
        }
    
        setFileSelected(true);
        setLoading(true);
    
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        try {
            // Fetch probabilities from your server
            const response = await fetch('https://syntheticeye-dev.onrender.com/predict_aletheia4_endpoint', {
                method: 'POST',
                body: formData
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();  // Convert the response to JSON
    
            if (data.CG !== undefined && data.GAN !== undefined && data.Real !== undefined) {
                // Fetch Grad-CAM image
                try {
                    const gradCamResponse = await fetch('https://syntheticeye-dev.onrender.com/predict-gradcam-aletheia4', {
                        method: 'POST',
                        body: formData,
                        headers: {
                            'Accept': 'image/jpeg'
                        }
                    });
    
                    if (!gradCamResponse.ok) {
                        throw new Error('Failed to load Grad-CAM image');
                    }
    
                    const gradCamImageBlob = await gradCamResponse.blob();
                    const gradCamImageUrl = URL.createObjectURL(gradCamImageBlob);
                    setResult({ probabilities: data, gradCamImage: gradCamImageUrl });
                } catch (gradCamError) {
                    console.error('Grad-CAM Error:', gradCamError);
                    setError("Failed to load Grad-CAM visualization.");
                    // Only set probabilities if Grad-CAM fails
                    setResult({ probabilities: data });
                }
            } else {
                throw new Error("Invalid response structure from probability endpoint.");
            }
        } catch (err) {
            console.error('Error:', err);
            setError("An error occurred. Please try again.");
        } finally {
            setLoading(false);
            setIsSubmitted(true);
        }
    };
    

    const getCategoryFromProbability = (probabilities) => {
        if (!probabilities || probabilities.CG === undefined || probabilities.GAN === undefined || probabilities.Real === undefined) {
            return { label: "Error", color: "transparent" };
        }
    
        console.log('Probabilities:', probabilities);
    
        const { CG, GAN, Real } = probabilities;
    
        const threshold = 0.2;
    
        if (CG > Real + threshold || GAN > Real + threshold) {
            return { label: "AI-Generated", color: "red" };
        } else if (Real > CG + threshold && Real > GAN + threshold) {
            return { label: "Likely Real", color: "green" };
        } else {
            return { label: "Unsure", color: "yellow" };
        }
    };

    const mockServerResponse = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                const responseData = {
                    "CG": 0.24691550731658936,
                    "GAN": 0.44691550731658936,
                    "Real": 0.5530510544776917
                };
                console.log('Mock response:', responseData);
                resolve(responseData);
            }, 200); // Simulate a delay
        });
    }

    const handleFileDrop = (event) => {
        event.preventDefault();
    
        let files = event.dataTransfer.files;
        if (files.length > 0) {
            handleFileChange({ target: { files } });
            fileInput.current.files = files; 
        }
    
        event.dataTransfer.clearData();
        setIsFileHovering(false);
    };

    useEffect(() => {
        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [imageSrc]);

    function triggerFileInput(e) {
        e.stopPropagation();
        fileInput.current.click();
    }


    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const imageURL = URL.createObjectURL(file);
            setImageSrc(imageURL);
            setFileSelected(true);
        } else {
            setImageSrc(null);
            setFileSelected(false);
        }
    };

    const [isFileHovering, setIsFileHovering] = useState(false);


    // Add a new state for managing the popup visibility
    const [showPopup, setShowPopup] = useState(false);

    // Function to toggle the popup
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const colorClasses = {
        green: 'bg-green-500',
        red: 'bg-red-500',
      };

    const ProgressBar = ({ label, value, color, tooltipContent }) => {
        const [showTooltip, setShowTooltip] = useState(false);
    
        return (
            <div className="flex items-center gap-3 mt-2">
                <span className="text-white w-24 flex items-center">
                    {label}
                    <div className="relative ml-2">
                        <AiOutlineInfoCircle
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            className="text-white cursor-help"
                        />
                        {showTooltip && (
                            <div className="absolute z-10 w-52 p-2 -mt-6 text-sm leading-tight text-white transform -translate-x-1/2 -translate-y-full bg-gray-800 rounded-md shadow-lg">
                                {tooltipContent}
                            </div>
                        )}
                    </div>
                </span>
                <div className="w-full bg-gray-700 rounded-full h-3.5 dark:bg-gray-700">
                    <div className={`${colorClasses[color]} h-3.5 rounded-full`} style={{ width: `${value}%` }}></div>
                </div>
                <span className="text-white">{value.toFixed(1)}%</span>
            </div>
        );
    };

    const Popup = () => {
        // Assuming 'result.probabilities' contains your probability data
        const { probabilities, gradCamImage } = result;
        const { CG, GAN, Real } = result.probabilities;
        const cgPercent = CG * 100;
        const ganPercent = GAN * 100;
        const realPercent = Real * 100;
    
        return (
            <div className={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 ${showPopup ? '' : 'hidden'}`}>
                <div className="relative top-20 mx-auto px-12 py-16 w-[600px] shadow-lg rounded-md bg-primary">
                    <div className="mt-3 text-center">
                        <h3 className="text-2xl leading-6 font-medium text-gray-100 font-righteous">Learn More (beta)</h3>
                        {/* Display Grad-CAM image */}
                        {gradCamImage && (
                        <div className="mt-6 mb-2 flex justify-center space-x-4">
                            <img src={gradCamImage} alt="Grad-CAM Analysis" className="max-w-full h-auto rounded-md" />
                            <div className="flex flex-col justify-center">
                                <p className="text-gray-200">
                                    This Grad-CAM visualization highlights the regions of the image that are most significant to Aletheia's decision. Hot regions (red) indicate areas of high importance, while cold regions (blue) are less influential.
                                </p>
                            </div>
                        </div>
                        )}

                        <div className="mt-2 px-7 py-5">                       
                            <ProgressBar
                                label="Real"
                                value={realPercent}
                                color="green"
                                tooltipContent="This is the probability that the face is real."
                            />
                            <ProgressBar
                                label="GAN"
                                value={ganPercent}
                                color="red"
                                tooltipContent="Indicates the chance that the face was created by a Generative Adversarial Network (GAN), such as StyleGAN, which can generate photorealistic images."
                            />
                            <ProgressBar
                                label="CG"
                                value={cgPercent}
                                color="red"
                                tooltipContent="This probability reflects whether the face is computer-generated, encompassing creations from AI models like Stable Diffusion to synthetic datasets like DigiFace-1M."
                            />
                        </div>
                        
                        <div className="items-center px-4 py-3">
                            <button onClick={togglePopup} className="px-4 py-2 bg-gray-700 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    


    return (
        <div className='bg-primary'>
        <Navbar />
        {showPopup && <Popup />}
        <div className={`main-content ${showPopup ? 'blur-effect' : ''}`}>
        <div className={`text-center font-poppins mt-6 ${isLoaded ? 'fade-in' : ''}`}>
                <div className='text-white md:text-8xl text-5xl pt-16 pb-2 font-white font-righteous'>Aletheia</div>
                <div className='font-secondary font-poppins md:text-xl text-lg md:tracking-widest tracking-wide pb-4'>AI-GENERATED FACE DETECTION</div>

                <div className="flex items-center justify-center">
                <div className="text-gray-300 text-sm w-5/6 md:w-4/6 text-center mb-4 md:text-sm">
                    Aletheia, named after the Greek goddess of truth, is our AI model that differentiates real human faces from AI-generated ones using advanced machine learning.
                </div>
        </div>

        <div className='absolute right-2/3 z-[0]'>
            <div className='blue__gradient h-64 w-80'></div>
        </div>

        <div className='absolute right-1/4 z-[0] top-1/3'>
            <div className='darkblue__gradient h-64 w-40'></div>
        </div>

        <div className='flex md:flex-row flex-col justify-center items-start mt-6 md:mx-24 mx-4'>

        <div className='md:w-1/2 md:mr-12 mb-6'> 
                    <div className='bg-gray-700 text-white mt-12 md:mt-0 mx-[-20px] md:p-0 p-4 only-mobile'>Our AI-generated face detection model is currently optimized for desktop use only. For full functionality, please access our site on a computer.</div>
                    <h3 className='text-white text-lg mb-2 mt-8'>Aletheia can detect: </h3>
                    
                    <FaceDetectors/>
                    
                </div>
                
                <div className='md:w-1/2 right-column'>
                <div className="relative">

                <div 
                    onClick={triggerFileInput} 
                    onDragOver={(e) => { e.preventDefault(); e.stopPropagation(); }} 
                    onDragEnter={(e) => { e.stopPropagation(); console.log('Drag Enter'); setIsFileHovering(true); }} 
                    onDragLeave={(e) => {
                        e.stopPropagation(); 
                        console.log('Drag Leave', e.currentTarget.contains(e.relatedTarget));
                        if (e.currentTarget.contains(e.relatedTarget)) return;
                        setIsFileHovering(false);
                    }}
                    onDrop={handleFileDrop}
                    className={`h-[272px] md:py-16 py-6 px-16 border-secondary ${fileSelected ? 'opacity-100' : 'opacity-70'} file_border_radius text-center cursor-pointer ${isFileHovering ? 'bg-gray-800' : ''} hover:bg-gray-800 text-white flex items-center justify-center overflow-hidden`}
                >
                    <input 
                        type="file" 
                        accept="image/*"
                        className="absolute top-0 left-0 w-[0px] h-[0px] opacity-0 cursor-pointer text-gray-300 text-xl" 
                        ref={fileInput} 
                        onChange={handleFileChange}
                    />

                    <div className={`${fileSelected ? 'opacity-90' : 'opacity-90'} flex items-center justify-center h-full w-full text-lg`}>
                        {
                            fileSelected 
                            ? imageSrc && <img src={imageSrc} alt="Uploaded Preview" className="uploaded-image-height-fill" />
                            : "Click or drop file here to upload"
                        }
                    </div>
                </div>
                    </div>

                    <div className="flex justify-center items-center md:w-full mx-auto"> 
                    <div className="w-full bg-white flex items-center justify-center p-[12px] mb-0 bg-opacity-0 privacy_box">
                        <div className="h-4"> {/* This will constrain the width to a readable length */}
                            <label className="flex items-center text-sm text-gray-300">
                                <input 
                                    type="checkbox" 
                                    checked={isConsentGiven} 
                                    onChange={() => setIsConsentGiven(prev => !prev)} 
                                    className="mr-2"
                                />
                                <span className="ml-2 text-white md:text-xs text-sm">I agree to the processing of this image as outlined in the <Link to="/privacy-policy" className="underline hover:text-blue-500">Privacy Policy</Link>.</span>
                            </label>
                        </div>
                    </div>
                    </div>

                    <button 
                            onClick={handleSubmit} 
                            className={`primary-gradient tracking-wider font-righteous  md:px-2 md:py-2 text-lg uppercase rounded-md hover:rounded-lg py-1 mt-4 w-full relativ md:w-6/6 mx-auto ${fileSelected ? 'opacity-100' : 'opacity-30'} cursor-${fileSelected ? 'pointer' : 'not-allowed'} mb-8`}
                        >
                            Submit
                        </button>

                        {loading && (
                            <div className='overlayStyle backdrop-blur text-white'>
                                <div className='containerStyle'>
                                    <DotLoader color={"#32ffd6"} size={80} />
                                    <p className='loading_text mt-12 text-lg'>Aletheia is thinking...</p>
                                </div>
                            </div>
                        )}

                        <div className='text-gray-300 mb-2'>Aletheia classifies this image as:</div>
                        {result && (
                            <div className="flex flex-col items-center justify-center font-righteous mb-4">
                                <div className={`circle-${getCategoryFromProbability(result.probabilities).color} md:text-lg text-md`}>
                                    {getCategoryFromProbability(result.probabilities).label}
                                </div>
                                {fileSelected && isConsentGiven && isSubmitted && (
                                    <button onClick={togglePopup} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        Learn More
                                    </button>
                                )}
                            </div>
                        )}

                    {error && <div className="text-red-500">{error}</div>}

                    
                    </div>
                </div>
                
                <div className="mt-8 left-0 w-full bg-red-500 bg-opacity-60 text-gray-200 text-center p-4 text-sm">
                    <b>Warning:</b> Although this model has archieved a high accuracy on mutlitple generators, please be mindful that its predictions may not always be correct.
                    </div>

                    <div className='text-gray-300 py-14 w-full bg-gray-50 bg-opacity-10'>
                        <h1 className='text-lg mb-4'>Training Data Sources:</h1>
                        <div className='md:mx-56 mx-2 text-sm'>

                        <div className="p-4 mb-4 bg-gray-900 rounded-lg shadow-md">
                        <p className="text-xs font-semibold">Reference:</p>
                        <p className="text-sm">
                            Liu, Z., Luo, P., Wang, X., & Tang, X. (2015). 
                            <i className="font-medium">Deep Learning Face Attributes in the Wild</i>. 
                            In Proceedings of International Conference on Computer Vision (ICCV), December 2015.
                        </p>
                        </div>

                       <div className="p-4 mb-4 bg-gray-900 rounded-lg shadow-md">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Bae, G., de La Gorce, M., Baltrušaitis, T., Hewitt, C., Chen, D., Valentin, J., Cipolla, R., & Shen, J. (2023).
                                <i className="font-medium">DigiFace-1M: 1 Million Digital Face Images for Face Recognition</i>. 
                                In 2023 IEEE Winter Conference on Applications of Computer Vision (WACV). IEEE.
                            </p>
                        </div>

                        <div className="p-4 mb-4 bg-gray-900 rounded-lg shadow-md">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Huang, G. B., Ramesh, M., Berg, T., & Learned-Miller, E. (2007).
                                <i className="font-medium">Labeled Faces in the Wild: A Database for Studying Face Recognition in Unconstrained Environments</i>. 
                                Technical Report No. 07-49, University of Massachusetts, Amherst, October 2007.
                            </p>
                        </div>

                        <div className="p-4 mb-4 bg-gray-900 rounded-lg shadow-md">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Kumar, N., Berg, A. C., Belhumeur, P. N., & Nayar, S. K. (2009). 
                                <i className="font-medium">Attribute and Simile Classifiers for Face Verification</i>. 
                                International Conference on Computer Vision (ICCV), 2009.
                            </p>
                        </div>

                        
                        <div className="p-4 mb-4 bg-gray-900 rounded-lg shadow-md">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Rothe, R., Timofte, R., & Van Gool, L. (2015). 
                                <i className="font-medium">DEX: Deep EXpectation of apparent age from a single image</i>. 
                                IEEE International Conference on Computer Vision Workshops (ICCVW), December 2015.
                            </p>
                        </div>

                        <div className="p-4 mb-4 bg-gray-900 rounded-lg shadow-md">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Rothe, R., Timofte, R., & Van Gool, L. (2015). 
                                <i className="font-medium">Deep expectation of real and apparent age from a single image without facial landmarks</i>. 
                                International Journal of Computer Vision
                            </p>
                        </div>

                        <div className="p-4 mb-4 bg-gray-900 rounded-lg shadow-md">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Zhang, Z., Song, Y., & Qi, H. (2017). 
                                <i className="font-medium">Age Progression/Regression by Conditional Adversarial Autoencoder</i>. 
                                IEEE Conference on Computer Vision and Pattern Recognition (CVPR). IEEE.
                            </p>
                        </div> <br />

                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/xhlulu/140k-real-and-fake-faces">The 140k Real and Fake Faces dataset created by Gaurav Dutta and Xhlulu.</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/jessicali9530/celeba-dataset">CelebFaces Attributes (CelebA) Dataset</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/iamtushara/face-detection-dataset?resource=download">Face Detection Dataset</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/vbmokin/real-and-fake-face-detection-size-400x400">Real and Fake Face Detection - size 400x400</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/dullaz/1m-ai-generated-faces-128x128">1M AI generated faces 128x128</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/selfishgene/synthetic-faces-high-quality-sfhq-part-4">Synthetic Faces High Quality (SFHQ) part 4</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/selfishgene/synthetic-faces-high-quality-sfhq-part-3">Synthetic Faces High Quality (SFHQ) part 3</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/selfishgene/synthetic-faces-high-quality-sfhq-part-2">Synthetic Faces High Quality (SFHQ) part 2</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/selfishgene/synthetic-faces-high-quality-sfhq-part-1">Synthetic Faces High Quality (SFHQ) part 1</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/jacobheldt/syntheticeye-diffusion-faces">SyntheticEye Diffusion Faces</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/bwandowando/faces-dataset-using-stable-diffusion-v14">Face Dataset Using Stable Diffusion v.1.4</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://generated.photos">Free Dataset for Academic Research by Generated Photos</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href='https://www.kaggle.com/datasets/vbookshelf/art-by-ai-neural-style-transfer'>Art by Ai - Neural Style Transfer</a> <br /> <br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href='https://synthesis.ai/diverse-human-faces-dataset/'>Open Dataset for ML Training: Diverse Human Faces</a> <br /> <br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href='https://huggingface.co/datasets/EnD-Diffusers/AI_Faces/viewer'>AI_Faces</a> <br /> <br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://lexica.art/">Lexica</a> <br /><br /> 
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.bing.com/images/create">We generated images using Bing Image Creator and ChatGPT</a> <br /><br /> 

                        </div>
                    </div>
                </div> 

                </div>

            </div>
            

    );
}

export default Aletheia;