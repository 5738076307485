import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { DotLoader } from "react-spinners";
import ImageDetectors from './ImageDetectors';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const Argus = () => {
    const [result, setResult] = useState({ probability: null });
    const [error, setError] = useState(null);
    const fileInput = useRef(null);
    const [fileSelected, setFileSelected] = useState(false);
    const [isConsentGiven, setIsConsentGiven] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [gradCamImage, setGradCamImage] = useState(null);
    const [isFileHovering, setIsFileHovering] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
            if (gradCamImage) {
                URL.revokeObjectURL(gradCamImage);
            }
        };
    }, [imageSrc, gradCamImage]);

    const handleSubmit = async () => {
        const selectedFile = fileInput.current?.files[0];

        setError(null);

        if (!isConsentGiven) {
            setError("You must agree to the processing of the image as outlined in the Privacy Policy.");
            return;
        }

        if (!selectedFile) {
            setError("Please select a file first.");
            setFileSelected(false);
            return;
        }

        setFileSelected(true);
        setLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch('https://syntheticeye-dev.onrender.com/predict-argus', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.probability !== undefined) {
                setResult({ probability: data.probability });
                setIsSubmitted(true);
            } else {
                throw new Error("Invalid response structure from probability endpoint.");
            }
        } catch (err) {
            console.error('Error:', err);
            setError("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const fetchGradCamImage = async () => {
        const selectedFile = fileInput.current?.files[0];

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const gradCamResponse = await fetch('https://syntheticeye-dev.onrender.com/predict-gradcam-argus', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'image/jpeg'
                }
            });

            if (!gradCamResponse.ok) {
                throw new Error('Failed to load Grad-CAM image');
            }

            const gradCamImageBlob = await gradCamResponse.blob();
            const gradCamImageUrl = URL.createObjectURL(gradCamImageBlob);
            setGradCamImage(gradCamImageUrl);
        } catch (gradCamError) {
            console.error('Grad-CAM Error:', gradCamError);
            setError("Failed to load Grad-CAM visualization.");
        }
    };

    const getCategoryFromProbability = (probability) => {
        if (probability < 0.2) return { label: "Likely AI-Generated", color: "red" };
        else if (probability < 0.4) return { label: "Possibly AI-Generated", color: "light-red" };
        else if (probability < 0.6) return { label: "Unsure", color: "yellow" };
        else if (probability < 0.8) return { label: "Probably Real", color: "light-green" };
        else if (probability < 1) return { label: "Likely Real", color: "green" };
        else return { label: "Debug", color: "green" };
    };

    const handleFileDrop = (event) => {
        event.preventDefault();

        let files = event.dataTransfer.files;
        if (files.length > 0) {
            handleFileChange({ target: { files } });
            fileInput.current.files = files;
        }

        event.dataTransfer.clearData();
        setIsFileHovering(false);
    };

    const triggerFileInput = (e) => {
        e.stopPropagation();
        fileInput.current.click();
    };

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const imageURL = URL.createObjectURL(file);
            setImageSrc(imageURL);
            setFileSelected(true);
        } else {
            setImageSrc(null);
            setFileSelected(false);
        }
    };

    const togglePopup = async () => {
        if (!showPopup) {
            await fetchGradCamImage();
        }
        setShowPopup(!showPopup);
    };

    const ProgressBar = ({ label, value, color, tooltipContent }) => {
        const [showTooltip, setShowTooltip] = useState(false);

        return (
            <div className="flex items-center gap-3 mt-2">
                <span className="text-white w-24 flex items-center">
                    {label}
                    <div className="relative ml-2">
                        <AiOutlineInfoCircle
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            className="text-white cursor-help"
                        />
                        {showTooltip && (
                            <div className="absolute z-10 w-52 p-2 -mt-6 text-sm leading-tight text-white transform -translate-x-1/2 -translate-y-full bg-gray-800 rounded-md shadow-lg">
                                {tooltipContent}
                            </div>
                        )}
                    </div>
                </span>
                <div className="w-full bg-gray-700 rounded-full h-3.5 dark:bg-gray-700">
                    <div className={`bg-${color}-500 h-3.5 rounded-full`} style={{ width: `${value}%` }}></div>
                </div>
                <span className="text-white">{value.toFixed(1)}%</span>
            </div>
        );
    };

    const Popup = () => {
        const { probability } = result;

        const probPercent = probability * 100;

        return (
            <div className={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 ${showPopup ? '' : 'hidden'}`}>
                <div className="relative top-20 mx-auto px-12 py-16 w-[600px] shadow-lg rounded-md bg-primary">
                    <div className="mt-3 text-center">
                        <h3 className="text-2xl leading-6 font-medium text-gray-100 font-righteous">Learn More (beta)</h3>
                        {gradCamImage && (
                            <div className="mt-6 mb-2 flex justify-center space-x-4">
                                <img src={gradCamImage} alt="Grad-CAM Analysis" className="w-72 h-72 object-cover rounded-md" />
                                <div className="flex flex-col justify-center">
                                    <p className="text-gray-200">
                                        This Grad-CAM visualization highlights the regions of the image that are most significant to Argus's decision. Hot regions (red) indicate areas of high importance, while cold regions (blue) are less influential.
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="mt-2 px-7 py-5">
                            <ProgressBar
                                label="Probability"
                                value={probPercent}
                                color="green"
                                tooltipContent="This is the probability that the image is real."
                            />
                        </div>
                        <div className="items-center px-4 py-3">
                            <button onClick={togglePopup} className="px-4 py-2 bg-gray-700 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='bg-primary'>
            <Navbar />
            {showPopup && <Popup />}
            <div className={`main-content ${showPopup ? 'blur-effect' : ''}`}>
                <div className={`text-center font-poppins mt-6 ${isLoaded ? 'fade-in' : ''}`}>
                    <div className='text-white md:text-8xl text-5xl pt-16 pb-2 font-white font-righteous'>Argus</div>
                    <div className='font-secondary font-poppins md:text-xl text-lg md:tracking-widest tracking-wide pb-4'>General AI-Image Detection</div>
                    <div className="flex items-center justify-center">
                        <div className="text-gray-300 text-base w-full md:w-full text-center mb-4 md:text-base">
                            Argus, named after the mythological giant with a hundred eyes, is our AI model capable of distinguishing between authentic and AI-generated images.
                        </div>
                    </div>
                    <div className='absolute right-2/3 z-[0]'>
                        <div className='blue__gradient h-64 w-80'></div>
                    </div>
                    <div className='absolute right-1/4 z-[0] top-1/3'>
                        <div className='darkblue__gradient h-64 w-40'></div>
                    </div>
                    <div className='flex md:flex-row flex-col justify-center items-start mt-6 md:mx-24 mx-4'>
                        <div className='md:w-1/2 md:mr-24 mb-6'>
                            <div className='bg-gray-700 text-white mt-12 md:mt-0 mx-[-20px] md:p-0 p-4 only-mobile'>Our AI-generated face detection model is currently optimized for desktop use only. For full functionality, please access our site on a computer.</div>
                            <h3 className='text-white text-lg mb-6 mt-8'>Argus can detect:</h3>
                            <ImageDetectors />
                        </div>
                        <div className='md:w-2/3 right-column'>
                            <div className="relative">
                                <div
                                    onClick={triggerFileInput}
                                    onDragOver={(e) => { e.preventDefault(); e.stopPropagation(); }}
                                    onDragEnter={(e) => { e.stopPropagation(); setIsFileHovering(true); }}
                                    onDragLeave={(e) => { e.stopPropagation(); if (!e.currentTarget.contains(e.relatedTarget)) setIsFileHovering(false); }}
                                    onDrop={handleFileDrop}
                                    className={`h-[272px] md:py-16 py-6 px-16 border-secondary ${fileSelected ? 'opacity-100' : 'opacity-70'} file_border_radius text-center cursor-pointer ${isFileHovering ? 'bg-gray-800' : ''} hover:bg-gray-800 text-white flex items-center justify-center overflow-hidden`}
                                >
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="absolute top-0 left-0 w-[0px] h-[0px] opacity-0 cursor-pointer text-gray-300 text-xl"
                                        ref={fileInput}
                                        onChange={handleFileChange}
                                    />
                                    <div className={`${fileSelected ? 'opacity-90' : 'opacity-90'} flex items-center justify-center h-full w-full text-lg`}>
                                        {fileSelected ? imageSrc && <img src={imageSrc} alt="Uploaded Preview" className="uploaded-image-height-fill" /> : "Click or drop file here to upload"}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center md:w-full mx-auto">
                                <div className="w-full bg-white flex items-center justify-center p-[12px] mb-0 bg-opacity-0 privacy_box">
                                    <div className="h-4">
                                        <label className="flex items-center text-sm text-gray-300">
                                            <input
                                                type="checkbox"
                                                checked={isConsentGiven}
                                                onChange={() => setIsConsentGiven(prev => !prev)}
                                                className="mr-2"
                                            />
                                            <span className="ml-2 text-white md:text-xs text-sm">I agree to the processing of this image as outlined in the <Link to="/privacy-policy" className="underline hover:text-blue-500">Privacy Policy</Link>.</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button
                                onClick={handleSubmit}
                                className={`primary-gradient tracking-wider font-righteous md:px-2 md:py-2 text-lg uppercase rounded-md hover:rounded-lg py-1 mt-4 w-full relativ md:w-6/6 mx-auto ${fileSelected ? 'opacity-100' : 'opacity-30'} cursor-${fileSelected ? 'pointer' : 'not-allowed'} mb-8`}
                            >
                                Submit
                            </button>
                            {loading && (
                                <div className='overlayStyle backdrop-blur text-white'>
                                    <div className='containerStyle'>
                                        <DotLoader color={"#32ffd6"} size={80} />
                                        <p className='loading_text mt-12 text-lg'>Argus is thinking...</p>
                                    </div>
                                </div>
                            )}
                            <div className='text-gray-300 mb-2'>Argus classifies this image as:</div>
                            {result.probability !== null && (
                                <div className="flex flex-col items-center justify-center font-righteous mb-4">
                                    <div className={`circle-${getCategoryFromProbability(result.probability).color} md:text-lg text-md`}>
                                        {getCategoryFromProbability(result.probability).label}
                                    </div>
                                    {fileSelected && isConsentGiven && isSubmitted && (
                                        <button onClick={togglePopup} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                            Learn More
                                        </button>
                                    )}
                                </div>
                            )}
                            {error && <div className="text-red-500">{error}</div>}
                        </div>
                    </div>
                </div>
                <div className="mt-8 left-0 w-full bg-red-500 bg-opacity-60 text-gray-200 text-center p-4 text-base">
                    <b>Warning:</b> Although this model has achieved a high accuracy on multiple generators, please be mindful that its predictions may not always be correct.
                </div>
                <div className='text-gray-300 py-14 w-full bg-gray-50 bg-opacity-10'>
                <h1 className='text-lg mb-4'>Training Data Sources:</h1>
                <div className='md:mx-56 mx-2 text-sm'>


                        <div className="p-4 bg-gray-900 rounded-lg shadow-md mb-6">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Wang, Z. J., Montoya, E., Munechika, D., Yang, H., Hoover, B., & Chau, D. H. (2022).
                                <i className="font-medium">DiffusionDB: A Large-Scale Prompt Gallery Dataset for Text-to-Image Generative Models</i>. 
                                arXiv:2210.14896 [cs].
                            </p>
                            <a href="https://arxiv.org/abs/2210.14896" className="text-xs text-blue-500 underline mt-2 inline-block" target="_blank" rel="noopener noreferrer">
                                Link: https://arxiv.org/abs/2210.14896
                            </a>
                        </div>

                        <div className="p-4 bg-gray-900 rounded-lg shadow-md mb-6">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Bird, J. J., & Lotfi, A. (2023). 
                                <i className="font-medium">CIFAKE: Image Classification and Explainable Identification of AI-Generated Synthetic Images</i>. 
                                arXiv preprint arXiv:2303.14126.
                            </p>
                            <a href="https://doi.org/10.48550/arXiv.2303.14126" className="text-xs text-blue-500 underline mt-2 inline-block" target="_blank" rel="noopener noreferrer">
                                DOI: 10.48550/arXiv.2303.14126
                            </a>
                        </div>

                        <div className="p-4 bg-gray-900 rounded-lg shadow-md mb-6">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Deng, J., Dong, W., Socher, R., Li, L.-J., Li, K., & Fei-Fei, L. (2009). 
                                <i className="font-medium">Imagenet: A large-scale hierarchical image database</i>. 
                                In <i>2009 IEEE Conference on Computer Vision and Pattern Recognition</i> (pp. 248–255). IEEE.
                            </p>
                        </div>

                        <div className="p-4 bg-gray-900 rounded-lg shadow-md mb-6">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Krizhevsky, A., & Hinton, G. (2009). 
                                <i className="font-medium">Learning multiple layers of features from tiny images</i>. 
                                Master's thesis, Department of Computer Science, University of Toronto. Citeseer.
                            </p>
                        </div>

                        <div className="p-4 bg-gray-900 rounded-lg shadow-md mb-6">
                            <p className="text-xs font-semibold">Reference:</p>
                            <p className="text-sm">
                                Koliha, N. (2024). 
                                <i className="font-medium">AI recognition dataset</i>. 
                                Kaggle.
                            </p>
                            <a href="https://www.kaggle.com/dsv/7501727" className="text-xs text-blue-500 underline mt-2 inline-block" target="_blank" rel="noopener noreferrer">
                                Available at: https://www.kaggle.com/dsv/7501727
                            </a>
                            <a href="https://doi.org/10.34740/KAGGLE/DSV/7501727" className="text-xs text-blue-500 underline mt-2 inline-block" target="_blank" rel="noopener noreferrer">
                                DOI: 10.34740/KAGGLE/DSV/7501727
                            </a>
                        </div>

                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://huggingface.co/datasets/dalle-mini/open-images">Dalle-Mini Open-Images</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/jacobheldt/syntheticeye-diffusion-faces">SyntheticEye Diffusion Faces</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/lprdosmil/unsplash-random-images-collection">Unsplash random images collection</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.kaggle.com/datasets/jacobheldt/syntheticeue-ai-generated-images-dataset">SyntheticEye AI-Generated Images Dataset</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.bing.com/images/create">We generated images using Bing Image Creator and ChatGPT</a> <br /><br /> 
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://lexica.art/">Lexica</a> <br /><br />
                        <a target='blank' className='underline p-2 bg-gray-900 rounded-lg shadow-md mb-6' href="https://www.craiyon.com/">Craiyon</a> <br /><br />
                        

                        </div>
                    </div>
                </div>
            </div>

    );
}

export default Argus;